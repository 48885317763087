let wasm;
export function __wbg_set_wasm(val) {
    wasm = val;
}


const lTextDecoder = typeof TextDecoder === 'undefined' ? (0, module.require)('util').TextDecoder : TextDecoder;

let cachedTextDecoder = new lTextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachedUint8Memory0 = null;

function getUint8Memory0() {
    if (cachedUint8Memory0 === null || cachedUint8Memory0.byteLength === 0) {
        cachedUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachedUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

const heap = new Array(128).fill(undefined);

heap.push(undefined, null, true, false);

let heap_next = heap.length;

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
    return instance.ptr;
}

let WASM_VECTOR_LEN = 0;

const lTextEncoder = typeof TextEncoder === 'undefined' ? (0, module.require)('util').TextEncoder : TextEncoder;

let cachedTextEncoder = new lTextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length, 1) >>> 0;
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len, 1) >>> 0;

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3, 1) >>> 0;
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
        ptr = realloc(ptr, len, offset, 1) >>> 0;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

let cachedInt32Memory0 = null;

function getInt32Memory0() {
    if (cachedInt32Memory0 === null || cachedInt32Memory0.byteLength === 0) {
        cachedInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachedInt32Memory0;
}

let cachedUint32Memory0 = null;

function getUint32Memory0() {
    if (cachedUint32Memory0 === null || cachedUint32Memory0.byteLength === 0) {
        cachedUint32Memory0 = new Uint32Array(wasm.memory.buffer);
    }
    return cachedUint32Memory0;
}

function getObject(idx) { return heap[idx]; }

function dropObject(idx) {
    if (idx < 132) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

function getArrayJsValueFromWasm0(ptr, len) {
    ptr = ptr >>> 0;
    const mem = getUint32Memory0();
    const slice = mem.subarray(ptr / 4, ptr / 4 + len);
    const result = [];
    for (let i = 0; i < slice.length; i++) {
        result.push(takeObject(slice[i]));
    }
    return result;
}

const BoardFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_board_free(ptr >>> 0));
/**
*/
export class Board {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Board.prototype);
        obj.__wbg_ptr = ptr;
        BoardFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        BoardFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_board_free(ptr);
    }
    /**
    * @param {string} data
    */
    constructor(data) {
        const ptr0 = passStringToWasm0(data, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.board_from_string(ptr0, len0);
        this.__wbg_ptr = ret >>> 0;
        return this;
    }
    /**
    * @returns {Board}
    */
    static default() {
        const ret = wasm.board_default();
        return Board.__wrap(ret);
    }
    /**
    * @param {Cell} cell
    */
    insert(cell) {
        _assertClass(cell, Cell);
        var ptr0 = cell.__destroy_into_raw();
        wasm.board_insert(this.__wbg_ptr, ptr0);
    }
    /**
    * @param {Cell} cell
    */
    remove(cell) {
        _assertClass(cell, Cell);
        wasm.board_remove(this.__wbg_ptr, cell.__wbg_ptr);
    }
    /**
    * @param {number} x
    * @param {number} y
    */
    set(x, y) {
        wasm.board_set(this.__wbg_ptr, x, y);
    }
    /**
    * @param {number} x
    * @param {number} y
    */
    unset(x, y) {
        wasm.board_unset(this.__wbg_ptr, x, y);
    }
    /**
    * @param {number} x
    * @param {number} y
    */
    toggle(x, y) {
        wasm.board_toggle(this.__wbg_ptr, x, y);
    }
    /**
    * @returns {Board}
    */
    copy() {
        const ret = wasm.board_copy(this.__wbg_ptr);
        return Board.__wrap(ret);
    }
    /**
    * @returns {(Cell)[]}
    */
    get_cells() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.board_get_cells(retptr, this.__wbg_ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            var v1 = getArrayJsValueFromWasm0(r0, r1).slice();
            wasm.__wbindgen_free(r0, r1 * 4, 4);
            return v1;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {Cell} cell
    * @returns {number}
    */
    surround_count(cell) {
        _assertClass(cell, Cell);
        const ret = wasm.board_surround_count(this.__wbg_ptr, cell.__wbg_ptr);
        return ret;
    }
    /**
    */
    evaluate() {
        wasm.board_evaluate(this.__wbg_ptr);
    }
    /**
    * @param {bigint} x_size_top
    * @param {bigint} x_size_bot
    * @param {bigint} y_size_top
    * @param {bigint} y_size_bot
    */
    output(x_size_top, x_size_bot, y_size_top, y_size_bot) {
        wasm.board_output(this.__wbg_ptr, x_size_top, x_size_bot, y_size_top, y_size_bot);
    }
}

const CellFinalization = (typeof FinalizationRegistry === 'undefined')
    ? { register: () => {}, unregister: () => {} }
    : new FinalizationRegistry(ptr => wasm.__wbg_cell_free(ptr >>> 0));
/**
*/
export class Cell {

    static __wrap(ptr) {
        ptr = ptr >>> 0;
        const obj = Object.create(Cell.prototype);
        obj.__wbg_ptr = ptr;
        CellFinalization.register(obj, obj.__wbg_ptr, obj);
        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.__wbg_ptr;
        this.__wbg_ptr = 0;
        CellFinalization.unregister(this);
        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_cell_free(ptr);
    }
    /**
    * @returns {bigint}
    */
    get x() {
        const ret = wasm.__wbg_get_cell_x(this.__wbg_ptr);
        return ret;
    }
    /**
    * @param {bigint} arg0
    */
    set x(arg0) {
        wasm.__wbg_set_cell_x(this.__wbg_ptr, arg0);
    }
    /**
    * @returns {bigint}
    */
    get y() {
        const ret = wasm.__wbg_get_cell_y(this.__wbg_ptr);
        return ret;
    }
    /**
    * @param {bigint} arg0
    */
    set y(arg0) {
        wasm.__wbg_set_cell_y(this.__wbg_ptr, arg0);
    }
    /**
    * @param {bigint} x
    * @param {bigint} y
    */
    constructor(x, y) {
        const ret = wasm.cell_new(x, y);
        this.__wbg_ptr = ret >>> 0;
        return this;
    }
    /**
    * @param {Cell} other
    * @returns {Cell}
    */
    mul(other) {
        _assertClass(other, Cell);
        const ret = wasm.cell_mul(this.__wbg_ptr, other.__wbg_ptr);
        return Cell.__wrap(ret);
    }
    /**
    * @param {Cell} other
    * @returns {Cell}
    */
    add(other) {
        _assertClass(other, Cell);
        const ret = wasm.cell_add(this.__wbg_ptr, other.__wbg_ptr);
        return Cell.__wrap(ret);
    }
}

export function __wbindgen_throw(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};

export function __wbg_cell_new(arg0) {
    const ret = Cell.__wrap(arg0);
    return addHeapObject(ret);
};

